import request from '@/utils/request2'

// 获取当日报工数据接口：api/
export function getCurDayBgData (params) {
  return request({
    url: '/api/wxjj/getCurDayBgData',
    method: 'get',
    params: params
  })
}

// 自动掉落件-工件下拉项目
export function getBggjList (params) {
  return request({
    url: '/api/wxjj/getBggjList',
    method: 'get',
    params: params
  })
}

// 自动掉落件-工序下拉项目
export function getBggxList (params) {
  return request({
    url: '/api/wxjj/getBggxList',
    method: 'get',
    params: params
  })
}

// 提交报工数量 
export function postOneToolsNum (params) {
  return request({
    url: '/api/wxjj/postOneToolsNum',
    method: 'get',
    params: params
  })
}

// 获取历史报工数据接口（默认显示当天）
export function getHisEmpBgList (params) {
  return request({
    url: '/api/wxjj/getHisEmpBgList',
    method: 'get',
    params: params
  })
}

// 自动掉落件-提交审核接口
export function postEmpDljBg (params) {
  return request({
    url: '/api/wxjj/postEmpDljBg',
    method: 'get',
    params: params
  })
}

// 修改历史报工数据接口
export function saveHisXgBg (params) {
  return request({
    url: '/api/wxjj/saveHisXgBg',
    method: 'get',
    params: params
  })
}

//------------审核
// 获取默认计产类别接口
export function getDefaultJxType (params) {
  return request({
    url: '/api/wxjj/getDefaultJxType',
    method: 'get',
    params: params
  })
}

// 获取待审核数据
export function getForCheckBgList (params) {
  return request({
    url: '/api/wxjj/getForCheckBgList',
    method: 'get',
    params: params
  })
}

// 批量审核
export function batchCheckBgList (data) {
  return request({
    url: '/api/wxjj/batchCheckBgList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 获取历史审核记录接口
export function getHisHasCheckList (params) {
  return request({
    url: '/api/wxjj/getHisHasCheckList',
    method: 'get',
    params: params
  })
}

// 修改单笔报工记录数量
export function saveBgNum (params) {
  return request({
    url: '/api/wxjj/saveBgNum',
    method: 'get',
    params: params
  })
}

// 首页点击按钮接口
export function beforeClickBtn (params) {
  return request({
    url: '/api/wxjj/beforeClickBtn',
    method: 'get',
    params: params
  })
}

// 获取当天集体报工数据接口
export function getCurDayGroupBgList (params) {
  return request({
    url: '/api/wxjj/getCurDayGroupBgList',
    method: 'get',
    params: params
  })
}

// 集体报工提交接口
export function postGroupBg (params) {
  return request({
    url: '/api/wxjj/postGroupBg',
    method: 'get',
    params: params
  })
}

// 获取集体报工历史数据接口
export function getHisGroupBgList (params) {
  return request({
    url: '/api/wxjj/getHisGroupBgList',
    method: 'get',
    params: params
  })
}

// 修改历史报工数据接口
export function saveHisGroupBg (params) {
  return request({
    url: '/api/wxjj/saveHisGroupBg',
    method: 'get',
    params: params
  })
}

// 获取当月工作时长记录接口
export function getCurMonthWorkTimeMaster (params) {
  return request({
    url: '/api/wxjj/getCurMonthWorkTimeMaster',
    method: 'get',
    params: params
  })
}

// 提交工时数据接口
export function postWorkTimeData (params) {
  return request({
    url: '/api/wxjj/postWorkTimeData',
    method: 'get',
    params: params
  })
}

// 获取人员明细 
export function getWorkTimeEmpList (params) {
  return request({
    url: '/api/wxjj/getWorkTimeEmpList',
    method: 'get',
    params: params
  })
}

// 提交人员工时明细接口
export function postWorkTimeDetail (data) {
  return request({
    url: '/api/wxjj/postWorkTimeDetail',
    method: 'post',
    data: data
  })
}

// 修改单笔待审核记录的合格数量 
export function saveForCheckHgNum (params) {
  return request({
    url: '/api/wxjj/saveForCheckHgNum',
    method: 'get',
    params: params
  })
}

// 获取当前用户默认字段值
export function getCurUserInfo (params) {
  return request({
    url: '/api/wxjj/getCurUserInfo',
    method: 'get',
    params: params
  })
}

// 暂存接口
export function saveZsInputData (params) {
  return request({
    url: '/api/wxjj/saveZsInputData',
    method: 'post',
    data: params
  })
}

// 获取注塑记录主表和子表记录接口
export function getZsInputData (params) {
  return request({
    url: '/api/wxjj/getZsInputData',
    method: 'get',
    params: params
  })
}

/**
 * 总装接口
 */

// 获取生产订单号列表接口 
export function getScDdList (params) {
  return request({
    url: '/api/wxjj/getScDdList',
    method: 'get',
    params: params
  })
}

// 根据生产订单号获取相关信息
export function getScDdExitValue (params) {
  return request({
    url: '/api/wxjj/getScDdExitValue',
    method: 'get',
    params: params
  })
}

// 暂存总装数据接口
export function saveZzInputData (params) {
  return request({
    url: '/api/wxjj/saveZzInputData',
    method: 'post',
    data: params
  })
}

// 返回总装主表及3个子表数据接口
export function getZzInputData (params) {
  return request({
    url: '/api/wxjj/getZzInputData',
    method: 'get',
    params: params
  })
}

// 新增一笔生产明细记录
export function addZzScmxRecord (params) {
  return request({
    url: '/api/wxjj/addZzScmxRecord',
    method: 'post',
    data: params
  })
}

// 修改一笔生产明细记录
export function saveZzScmxRecord (params) {
  return request({
    url: '/api/wxjj/saveZzScmxRecord',
    method: 'post',
    data: params
  })
}

// 删除多笔生产明细记录
export function deleteZzScmxRecord (params) {
  return request({
    url: '/api/wxjj/deleteZzScmxRecord',
    method: 'get',
    params: params
  })
}

// 新增一笔人员明细记录
export function addZzEmpRecord (params) {
  return request({
    url: '/api/wxjj/addZzEmpRecord',
    method: 'post',
    data: params
  })
}

// 修改一笔人员明细记录
export function saveZzEmpRecord (params) {
  return request({
    url: '/api/wxjj/saveZzEmpRecord',
    method: 'post',
    data: params
  })
}

// 删除多笔人员明细记录
export function deleteZzEmpRecord (params) {
  return request({
    url: '/api/wxjj/deleteZzEmpRecord',
    method: 'get',
    params: params
  })
}

// 提交总装数据接口
export function postZzInputData (params) {
  return request({
    url: '/api/wxjj/postZzInputData',
    method: 'get',
    params: params
  })
}

// 提交接口
export function postZsInputData (params) {
  return request({
    url: '/api/wxjj/postZsInputData',
    method: 'get',
    params: params
  })
}

// 获取待审批列表接口
export function getTmForCheckList (params) {
  return request({
    url: '/api/wxjj/get_Tm_ForCheckList',
    method: 'get',
    params: params
  })
}
// 获取待提交列表接口
export function getTmForPostList (params) {
  return request({
    url: '/api/wxjj/get_Tm_ForPostList',
    method: 'get',
    params: params
  })
}
// 获取已提交列表接口
export function get_Tm_ForHasPostList (params) {
  return request({
    url: '/api/wxjj/get_Tm_ForHasPostList',
    method: 'get',
    params: params
  })
}
// 获取已审批列表接口
export function getTmHasCheckList (params) {
  return request({
    url: '/api/wxjj/get_Tm_HasCheckList',
    method: 'get',
    params: params
  })
}
// 获取不同意列表接口
export function getTmNotAgreeList (params) {
  return request({
    url: '/api/wxjj/get_Tm_NotAgreeList',
    method: 'get',
    params: params
  })
}

// 同意单据接口
export function agreeTmList (params) {
  return request({
    url: '/api/wxjj/agreeTmList',
    method: 'get',
    params: params
  })
}

// 不同意单据接口

export function notAgreeTmlist (params) {
  return request({
    url: '/api/wxjj/notAgreeTmlist',
    method: 'get',
    params: params
  })
}

// 获取订单号列表接口
export function getPf2OrderIdList (params) {
  return request({
    url: '/api/wxjj/getPf2OrderIdList',
    method: 'get',
    params: params
  })
}

// 根据生产订单号获取信息接口
export function getPf2OrderIdExitValue (params) {
  return request({
    url: '/api/wxjj/getPf2OrderIdExitValue',
    method: 'get',
    params: params
  })
}

// 获取产量明细配置接口
export function getPf2ClmxConfig (params) {
  return request({
    url: '/api/wxjj/getPf2ClmxConfig',
    method: 'get',
    params: params
  })
}

// 暂存喷粉登记2信息接口
export function savePf2InputData (params) {
  return request({
    url: '/api/wxjj/savePf2InputData',
    method: 'post',
    data: params
  })
}

// 返回喷粉登记2主表和子表信息接口
export function getPf2InputData (params) {
  return request({
    url: '/api/wxjj/getPf2InputData',
    method: 'get',
    params: params
  })
}

// 修改喷粉登记2生产明细接口
export function savePf2ScmxRecord (params) {
  return request({
    url: '/api/wxjj/savePf2ScmxRecord',
    method: 'post',
    data: params
  })
}

// 提交喷粉登记2信息接口
export function postPf2InputData (params) {
  return request({
    url: '/api/wxjj/postPf2InputData',
    method: 'get',
    params: params
  })
}

// 获取名单接口
export function getPf1EmpList (params) {
  return request({
    url: '/api/wxjj/getPf1EmpList',
    method: 'get',
    params: params
  })
}

// 暂存喷粉登记1接口
export function savePf1InputData (params) {
  return request({
    url: '/api/wxjj/savePf1InputData',
    method: 'post',
    data: params
  })
}

// 返回喷粉登记1主表和子表信息接口
export function getPf1InputData (params) {
  return request({
    url: '/api/wxjj/getPf1InputData',
    method: 'get',
    params: params
  })
}

// 新增喷粉登记1生产明细接口
export function addPf1ScmxRecord (params) {
  return request({
    url: '/api/wxjj/addPf1ScmxRecord',
    method: 'post',
    data: params
  })
}

// 修改喷粉登记1生产明细接口
export function savePf1ScmxRecord (params) {
  return request({
    url: '/api/wxjj/savePf1ScmxRecord',
    method: 'post',
    data: params
  })
}

// 删除喷粉登记1生产明细接口
export function deletePf1ScmxRecord (params) {
  return request({
    url: '/api/wxjj/deletePf1ScmxRecord',
    method: 'get',
    params: params
  })
}

// 新增喷粉登记1人员明细接口
export function addPf1EmpRecord (params) {
  return request({
    url: '/api/wxjj/addPf1EmpRecord',
    method: 'post',
    data: params
  })
}

// 修改喷粉登记1人员明细接口
export function savePf1EmpRecord (params) {
  return request({
    url: '/api/wxjj/savePf1EmpRecord',
    method: 'post',
    data: params
  })
}

// 删除喷粉登记1人员明细接口
export function deletePf1EmpRecord (params) {
  return request({
    url: '/api/wxjj/deletePf1EmpRecord',
    method: 'get',
    params: params
  })
}

// 提交喷粉登记1信息接口
export function postPf1InputData (params) {
  return request({
    url: '/api/wxjj/postPf1InputData',
    method: 'get',
    params: params
  })
}

// 获取五金产品相关信息
export function getWjBarCodeInfo (params) {
  return request({
    url: '/api/wxjj/getWjBarCodeInfo',
    method: 'get',
    params: params
  })
}

// 获取五金工序列表接口
export function getWjGxList (params) {
  return request({
    url: '/api/wxjj/getWjGxList',
    method: 'get',
    params: params
  })
}

// 暂存五金登记数据接口
export function saveWjInputData (params) {
  return request({
    url: '/api/wxjj/saveWjInputData',
    method: 'post',
    data: params
  })
}

// 返回五金登记主表和子表信息接口
export function getWjInputData (params) {
  return request({
    url: '/api/wxjj/getWjInputData',
    method: 'get',
    params: params
  })
}

// 新增五金登记生产明细接口
export function addWjScmxRecord (params) {
  return request({
    url: '/api/wxjj/addWjScmxRecord',
    method: 'post',
    data: params
  })
}

// 修改五金登记生产明细接口
export function saveWjScmxRecord (params) {
  return request({
    url: '/api/wxjj/saveWjScmxRecord',
    method: 'post',
    data: params
  })
}

// 删除五金登记生产明细接口
export function deleteWjScmxRecord (params) {
  return request({
    url: '/api/wxjj/deleteWjScmxRecord',
    method: 'get',
    params: params
  })
}

// 提交五金登记信息接口
export function postWjInputData (params) {
  return request({
    url: '/api/wxjj/postWjInputData',
    method: 'get',
    params: params
  })
}

// 获取指定日期掉落件的工序信息接口
export function getBgTempToolsList (params) {
  return request({
    url: '/api/wxjj/getBgTempToolsList',
    method: 'get',
    params: params
  })
}

// 2.保存自动掉落件报工接口
export function saveBgTempToolsList (params) {
  return request({
    url: '/api/wxjj/saveBgTempToolsList',
    method: 'post',
    data: params
  })
}

// 获取车间人员明细接口
export function getLineListDetail (params) {
  return request({
    url: '/api/wxjj/getLineListDetail',
    method: 'get',
    params: params
  })
}

// 获取车间列表接口
export function getWorkShopList (params) {
  return request({
    url: '/api/wxjj/getWorkShopList',
    method: 'get',
    params: params
  })
}

// 获取车间所属产线列表接口
export function getWorkShopLineList (params) {
  return request({
    url: '/api/wxjj/getWorkShopLineList',
    method: 'get',
    params: params
  })
}

// 保存车间产线人员记录接口
export function saveLineEmpRecord (params) {
  return request({
    url: '/api/wxjj/saveLineEmpRecord',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

// 删除车间产线人员记录接口
export function deleteLineEmpRecord (params) {
  return request({
    url: '/api/wxjj/deleteLineEmpRecord',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

// 获取注塑登记的实际机台列表接口
export function getZsSjjtList (params) {
  return request({
    url: '/api/wxjj/getZsSjjtList',
    method: 'get',
    params: params
  })
}

// 待办事项--删除未提交记录接口
export function deleteForPostRecord (params) {
  return request({
    url: '/api/wxjj/deleteForPostRecord',
    method: 'get',
    params: params
  })
}

// 获取五金订单工序明细接口
export function getWjOrderDetail (params) {
  return request({
    url: '/api/wxjj/getWjOrderDetail',
    method: 'get',
    params: params
  })
}

// 提交五金订单工序明细接口
export function saveWjOrderDetail (params) {
  return request({
    url: '/api/wxjj/saveWjOrderDetail',
    method: 'post',
    data: params,
    // headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

// 工作时长-人员明细-新增人员接口
export function batchAddWorkTimeEmpDetail (params) {
  return request({
    url: '/api/wxjj/batchAddWorkTimeEmpDetail',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}

// 工作时长-人员明细-删除人员接口
export function batchDeleteWorkTimeEmpDetail (params) {
  return request({
    url: '/api/wxjj/batchDeleteWorkTimeEmpDetail',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
  })
}