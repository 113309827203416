<template>
  <!-- 工作时长 -->
  <div>
    <div class="workingHours_main">
      <van-nav-bar
        :left-text="pageTitle"
        left-arrow
        @click-left="onClickLeft"
        :fixed="true"
      >
        <template #right>
          <div class="date">
            <i class="fa fa-calendar"></i>
            <div @click="checkEd('bd')">{{ bd }}</div>
            <i class="fa fa-sort-desc" style="padding-left:3px"></i>
            <div class="faRight" style="padding:0 5px">至</div>
            <div @click="checkEd('ed')">{{ ed }}</div>
            <i class="fa fa-sort-desc" style="padding-left:3px"></i>
          </div>
        </template>
      </van-nav-bar>
      <van-loading v-if="loadindData" type="spinner" color="#1989fa" vertical
        >加载中...</van-loading
      >
      <div class="content" v-if="!loadindData">
        <div class="today_content" v-if="workTimeList.length > 0">
          <div class="date">
            线别：
            {{ linename }}
          </div>
          <div class="work_list">
            <div
              class="work_box"
              v-for="(to, idx) in workTimeList"
              :key="idx + 'to'"
            >
              <div class="a_row">
                <div class="text_left">
                  <i class="fa fa-calendar-check-o"></i>
                  {{ to.yymmdd }}
                </div>
                <div
                  class="text_right"
                  v-if="to.ispost"
                  @click="personInfo(to)"
                >
                  <i class="fa fa-list-alt"></i>
                  人员明细
                </div>
              </div>
              <van-divider />
              <div class="baogong">
                <van-field
                  v-model="to.linename"
                  readonly
                  label="线别："
                  left-icon="cluster-o"
                />
              </div>
              <van-divider />
              <div class="baogong">
                <van-field
                  class="canEdit"
                  v-model="to.worktimes"
                  clearable
                  type="number"
                  label="工作时数："
                  left-icon="underway-o"
                  placeholder="工作时数"
                />
                <!-- <van-button type="info"
                            size="small"
                            @click="subimtToday(to)">{{to.ispost?'重新提交':'提交'}}</van-button> -->
              </div>
              <div class="baogong">
                <van-field
                  class="canEdit"
                  v-model="to.overtimes"
                  clearable
                  type="number"
                  label="加班工时："
                  left-icon="underway-o"
                  placeholder="加班工时"
                />
                <van-button type="info" size="small" @click="subimtToday(to)">{{
                  to.ispost ? '重新提交' : '提交'
                }}</van-button>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else image="search" description="暂无数据" />
      </div>
      <div class="paging_box">
        <van-pagination
          v-model="currentPage"
          :total-items="totalItems"
          :items-per-page="20"
          :show-page-size="3"
          force-ellipses
          @change="changePage"
        />
      </div>
    </div>

    <!-- 选择日期弹窗 -->
    <van-popup v-model="showCalendar" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        :title="'选择年月日'"
        @confirm="onConfirmCal"
        @cancel="showCalendar = false"
      />
    </van-popup>

    <!-- 提示信息弹窗 -->
    <van-dialog
      v-model="showHintDia"
      class="Automatic_report hint_dialog"
      :show-confirm-button="false"
    >
      <template #title>
        <div style="height:60px"></div>
      </template>
      <div class="content">
        <img
          :src="
            require(isSuccess
              ? '@/assets/img/job_booking11.png'
              : '@/assets/img/job_booking10.png')
          "
          alt
        />
        <div class="hint_msg">{{ hintMessage }}</div>
        <van-button type="info" size="small" round @click="showHintDia = false"
          >好的</van-button
        >
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { parseTime } from '@/utils'
import { getCurMonthWorkTimeMaster, postWorkTimeData } from '@api/wxjj.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      currentDate: new Date(),
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      bd: parseTime(new Date(), '{y}-{m}-{d}'),
      ed: parseTime(new Date(), '{y}-{m}-{d}'),
      whichDate: 'bd',
      showCalendar: false,
      workTimeList: [],
      initWorkTimeList: [],
      linename: '',
      showHintDia: false,
      isSuccess: true,
      hintMessage: '',
      loadindData: false,
      currentPage: 1,
      totalItems: 0
    }
  },
  computed: {
    pageTitle() {
      let title = '工作时长'
      if (this.$route.query) {
        title = this.$route.query.title
      }
      return title
    }
  },
  watch: {
    bd(n) {
      this.getCurMonthWorkTimeMasterData()
    },
    ed(n) {
      this.getCurMonthWorkTimeMasterData()
    }
  },
  created() {
    this.getCurMonthWorkTimeMasterData()
  },
  methods: {
    // 分页操作
    changePage() {
      this.workTimeList = this.initWorkTimeList.slice(
        (this.currentPage - 1) * 20,
        this.currentPage * 20
      )
    },
    // 选择筛选日期
    checkEd(val) {
      this.whichDate = val
      this.showCalendar = true
    },
    // 回退
    onClickLeft() {
      this.$router.push('/home')
    },
    // 人员明细
    personInfo(to) {
      this.$router.push({
        path: '/personnelDetails',
        query: {
          autoid: to.autoid,
          linename: to.linename,
          yymmdd: to.yymmdd
        }
      })
    },
    // 提交报工数量
    subimtToday(to) {
      if (to.worktimes && to.overtimes) {
        postWorkTimeData({
          autoid: to.autoid,
          worktimes: to.worktimes,
          overtimes: to.overtimes,
          username: this.userInfo.username
        }).then(res => {
          this.showHintDia = true
          if (res.data[0].info == '') {
            this.isSuccess = true
            to.ispost = true
            this.hintMessage = '提交工时成功'
            this.getCurMonthWorkTimeMasterData()
          } else {
            this.isSuccess = false
            to.ispost = res.data[0].show_dt_emplist
            this.hintMessage = res.data[0].info
          }
        })
      } else {
        this.showHintDia = true
        this.isSuccess = false
        this.hintMessage = '工作时数与加班工时不允许为空！'
      }
    },
    // 日历日期选择回调
    onConfirmCal(date) {
      if (this.whichDate == 'bd') {
        this.bd = parseTime(date, '{y}-{m}-{d}')
      } else {
        this.ed = parseTime(date, '{y}-{m}-{d}')
      }
      this.showCalendar = false
    },
    // 获取当月工作时长记录
    getCurMonthWorkTimeMasterData() {
      this.loadindData = true
      getCurMonthWorkTimeMaster({
        bd: this.bd,
        ed: this.ed,
        username: this.userInfo.username
      })
        .then(res => {
          let result = JSON.parse(JSON.stringify(res.data))
          this.initWorkTimeList = result
          this.totalItems = result.length
          this.linename = result[0] ? result[0].linename : ''
          this.$nextTick(() => {
            this.workTimeList = this.initWorkTimeList.slice(
              (this.currentPage - 1) * 20,
              this.currentPage * 20
            )
          })
        })
        .finally(() => {
          this.loadindData = false
        })
    }
  }
}
</script>

<style scoped lang="less">
.hint_dialog {
  .content {
    text-align: center;
    img {
      width: 160px;
      height: 160px;
    }
    .hint_msg {
      margin-top: 20px;
      font-size: 32px;
      font-weight: bold;
    }
    .van-button {
      margin: 40px 0;
      width: 240px;
      font-size: 28px;
    }
  }
}
.workingHours_main {
  height: 100vh;
  background-image: url('~@/assets/img/job_booking01.png');
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 1.22667rem;
  .van-nav-bar {
    height: 92px;
    background: #bcdfff;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }
      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }
    /deep/.van-nav-bar__title {
      color: #333;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/.van-nav-bar__right {
      .date {
        font-size: 28px;
        display: flex;
        align-items: center;
        .fa-calendar {
          margin-right: 6px;
        }
        .faRight {
          i {
            margin-left: 6px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .van-hairline--bottom::after {
    border: unset;
  }
  .van-loading {
    margin-top: 100%;
    height: calc(100% - 212px);
  }
  .content {
    height: calc(100% - 212px);
    margin-top: 92px;
    overflow-y: auto;
    .today_content {
      .date {
        font-size: 28px;
        padding: 20px;
        display: flex;
        align-items: center;
        .fa-calendar {
          margin-right: 6px;
        }
        .faRight {
          i {
            margin-left: 6px;
            margin-bottom: 16px;
          }
        }
      }
      .work_list {
        .work_box {
          font-size: 28px;
          background: #fff;
          box-shadow: 8px 6px 30px 0px rgba(175, 175, 175, 0.21);
          border-radius: 10px;
          margin: 20px;
          padding: 20px;
          .a_row {
            display: flex;
            margin-bottom: 20px;
            .text_left {
              flex: 1;
              color: #000000;
              text-align: left;
            }
            .text_right {
              flex: 1;
              text-align: right;
              color: #005ed7;
            }
          }
          .van-divider {
            margin: 20px 0;
          }
          .baogong {
            display: flex;
            align-items: center;
            /deep/.van-field {
              padding: 0;
              flex: 1;
            }
            /deep/.canEdit {
              .van-field__left-icon {
                color: #007bee;
              }
              .van-field__label {
                color: #007bee;
              }
            }
            .van-cell::after {
              border-bottom: 0;
            }
            .van-button {
              width: 150px;
            }
          }
        }
      }
    }
  }
  .paging_box {
    height: 120px;
    display: flex;
    align-items: flex-end;
    .van-pagination {
      width: 100%;
    }
  }
}
</style>
